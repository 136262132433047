<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/02-2banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">智慧监管执法</div>
            <div class="head-explain-content">推行“互联网+监管”</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">解决方案</span></div>
            <div class="head-list-item"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="solution-special">专业覆盖公共场所、生活饮用水、医疗卫生、学校卫生、职业卫生、传染病防治等十一个领域</div>
                <img src="../assets/image/example/02-2业务痛点.svg" class="solution-special-img"/>
                <div class="purchase-title">智慧卫监综管平台</div>
                <div class="purchase-content">智慧卫监平台产品主要是面向各级卫生健康监督部门提供的日常业务管理和执法办案信息管理工作平台</div>
                <div class="purchase-title">执法监督全程记录</div>
                <div class="purchase-content">规范了执法人员的执法行为，有效保护执法人员的正当权益及保护执法人员安全，提高执法工作落实的质量，提升执法队伍管理水平，提升执法人员的专业形象及工作效率<br/>
                无法及时掌握设备停机及故障原因，只能通过层层人工判断做出决策;</div>
                <div class="purchase-title">医疗多元监管平台</div>
                <div class="purchase-content">建立事中、事前监管体系，强调实动态监控，监控手段主要包括(标值、离群、趋势、风险)，医疗卫生多示化监管以医疗机构基础数据为支撑，全面展现医疗机构基础信息、日常运行及综合监管情况，实时监测、动态监管医疗机构依法执业自查开展情况、质控中心监管情况、医疗废物处置等情况，定期导入医疗机构绩效、第三方社会满意度、医疗机构投诉举报、信用评价等数据</div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 81rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../assets/image/example/02-2解决方案.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">方案收益</div>
            </div>
            <div class="purchase-content-right">
                <img class="benefits-img" src="../assets/image/example/02-2多元监管平台.png"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 69.1rem;
}
.benefits-img{
    width: 106.3rem;
    height: 66.2rem;
}
.solution-special{
    margin: 0 0 0 35.6rem;
    height: 1.6rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #11A84F;
    line-height: 2.4rem;
}
.solution-special-img{
    width: 89.1rem;
    height: 1.1rem;
    margin: 1.2rem 0 6.1rem 6.4rem;
}
</style>
